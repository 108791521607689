import { useTranslation } from 'react-i18next'

function formatDuration(t, seconds) {
  if (!Number.isInteger(seconds) || seconds == 0) return '\u00A0'
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  seconds = seconds % 60
  minutes = minutes % 60
  return t('reports.shortDuration', { hours, minutes, seconds })
}

export default function ReportCell(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { data } = props
  const time = new Date(data.occurredAt)
  const dateStr = time.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })
  const timeStr = time.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
  return (
    <div className='row'>
      <div className='col'>
        <p className="subtitle">{data.blamedUser || '\u00A0' }</p>
        <p>{t(`reports.action.${data.action}`)}</p>
        <p className="subtitle">{data.resourceName || data.resourceSerial || '\u00A0'}</p>
      </div>
      <div className='col'>
        <p className='subtitle'>{dateStr}</p>
        <p>{timeStr}</p>
        <p className='subtitle'>{formatDuration(t, data.duration)}</p>
      </div>
    </div>
  )
}
