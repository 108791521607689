import { useTranslation } from 'react-i18next'
import { useOrganization, useMe } from 'global'
import { KeyType } from 'enums'
import {
  Button,
  PageHeader,
  Duration,
  OrganizationEdit,
  openModal,
  closeModal,
} from 'components'
import './styles.scss'

export default function OrganizationDetails() {
  const { t } = useTranslation(null, { useSuspense: false })
  const { organization: org, address } = useOrganization()
  const { me } = useMe()

  const edit = () => openModal(<OrganizationEdit onFinish={closeModal} />)

  if (!org) return <></>
  return (
    <div className='organization-details-comp page'>
      <PageHeader title={org?.name}>
        {me?.canEditOrg && <Button flat icon='edit' onClick={edit} />}
      </PageHeader>
      <div className='content'>
        <div className='row'>
          <div className='col'>
            <h3>{t('organizationDetails.contact')}</h3>
            <p className='subtitle'>{t('organizationDetails.contactName')}</p>
            <p>{org?.contactName || ''}</p>
            <p className='subtitle'>{t('organizationDetails.contactEmail')}</p>
            <p>{org?.contactEmail || ''}</p>
            <p className='subtitle'>{t('organizationDetails.contactPhone')}</p>
            <p>{org?.contactPhone || ''}</p>
          </div>

          <div className='col'>
            <h3>{t('resource.key', {count: 100})}</h3>
            {org?.keyExpTimeIntervalEnabled ? (
              <>
                <p className='subtitle'>{t('organizationDetails.keyExpirationInterval')}</p>
                {<Duration milliseconds={org?.keyExpTimeInterval * 1000} />}
              </>
            ) : (
              <>
                <p className='subtitle'>{t('organizationDetails.keyExpiration')}</p>
                <p>{t('organizationDetails.noExpiration')}</p>
              </>
            )}
            <p className='subtitle'>{t('organizationDetails.keyBackupLimit')}</p>
            <p>{org?.maxBackupKeys || t('global.unlimited')}</p>
            <p className='subtitle'>{t('organizationDetails.keyAllowedTypes')}</p>
            <ul>
              {org?.allowedKeyTypes?.map(kt => (
                <li key={kt}>{t(`key.type.${KeyType(kt)}`)}</li>
              ))}
            </ul>
          </div>

          <div className='col'>
            <h3>{t('organizationDetails.other')}</h3>
            <p className='subtitle'>{t('organizationDetails.subdomain')}</p>
            <p>{org?.subdomain || ''}</p>
          </div>
        </div>

        <div className='row'>
          <div className='col address'>
            <h3>{t('address.address')}</h3>
            <p className='subtitle'>{t('address.address')}</p>
            <p>{address?.address1}</p>
            <p>{address?.address2}</p>
            <p>{address?.address3}</p>
            <p className='subtitle'>{t('address.city')}</p>
            <p>{address?.city}</p>
            <p className='subtitle'>{t('address.state')}</p>
            <p>{address?.state}</p>
            <p className='subtitle'>{t('address.postalCode')}</p>
            <p>{address?.zip}</p>
            <p className='subtitle'>{t('address.country')}</p>
            <p>{address?.country}</p>
          </div>
        </div>

      </div>
    </div>
  )
}
