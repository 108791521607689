import { useTranslation } from 'react-i18next'
import { useStore, useMe } from 'contexts'
import { KeyType } from 'enums'
import { Button, PageHeader } from 'components'
import './styles.scss'

export default function StoreDetailsScreen(props) {
  const { onProvision: provision, onEdit: edit } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { store, region, address } = useStore()
  const { me } = useMe()

  return (
    <div className='store-details-comp page'>
      <PageHeader title={store?.name}>
        {me?.canProvision && <Button className='provision-btn' onClick={provision}>{t('storeDetails.startProvisionMode')}</Button>}
        {me?.canEditStore && <Button flat icon='edit' onClick={edit}/>}
      </PageHeader>
      <div className='content'>
        <div className='row'>

          <div className='col'>
            <h3>{t('storeDetails.contact')}</h3>
            <p className='subtitle'>{t('storeDetails.contactName')}</p>
            <p>{store?.contactName || ''}</p>
            <p className='subtitle'>{t('storeDetails.contactEmail')}</p>
            <p>{store?.contactEmail || ''}</p>
            <p className='subtitle'>{t('storeDetails.contactPhone')}</p>
            <p>{store?.contactPhone || ''}</p>
          </div>

          <div className='col'>
            <h3>{t('resource.key', {count: 100})}</h3>
            <p className='subtitle'>{t('storeDetails.keyAllowedTypes')}</p>
            <ul>
              {store?.allowedKeyTypes?.map(kt => (
                <li key={kt}>{t(`key.type.${KeyType(kt)}`)}</li>
              ))}
            </ul>
          </div>

          <div className='col'>
            <h3>{t('storeDetails.other')}</h3>
            <p className='subtitle'>{t('storeDetails.branchCode')}</p>
            <p>{store?.branchCode || ''}</p>
            <p className='subtitle'>{t('storeDetails.region')}</p>
            <p>{region?.name || ''}</p>
            <p className='subtitle'>{t('storeDetails.timeZone')}</p>
            <p>{store?.timeZone || ''}</p>
            <p></p>
          </div>
        </div>

        <div className='row'>
          <div className='col address'>
            <h3>{t('address.address')}</h3>
            <p className='subtitle'>{t('address.address')}</p>
            <p>{address?.address1}</p>
            <p>{address?.address2}</p>
            <p>{address?.address3}</p>
            <p className='subtitle'>{t('address.city')}</p>
            <p>{address?.city}</p>
            <p className='subtitle'>{t('address.state')}</p>
            <p>{address?.state}</p>
            <p className='subtitle'>{t('address.postalCode')}</p>
            <p>{address?.zip}</p>
            <p className='subtitle'>{t('address.country')}</p>
            <p>{address?.country}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
