import { createContext, useContext } from 'react'
import { useSession, useOrganization } from 'global'
import { IdleTimer } from 'components'
// import useErrors from './errors'

const apiHost = getAPIHost()
const clientID = process.env.REACT_APP_CLIENT_ID
const clientSecret = process.env.REACT_APP_CLIENT_SECRET

// Export the provider identity for mocking/impersonating the api provider in tests
const Context = createContext()
export const CustomProvider = Context.Provider

// The api context instance that has all endpoints defined as simple functions
class API {
  static get instance() {
    this._instance = this._instance || new API()
    return this._instance
  }

  login(username, password) {
    return request('POST', 'authentications', null, {
      authentication: {
        authType: 'password',
        clientId: clientID,
        clientSecret,
        username,
        password,
      },
    }, null) // explicity _don't_ pass a token, even if we have one in the session
  }

  logout() { return request('DELETE', 'authentications') }

  otpRequest(otp, token) {
    return request('POST', 'onetime_code', null, {
      onetime_code: {
        token,
        onetime_code: otp,
      },
    }, token)
  }

  // OAuth
  getOAuthProvider(teamKey) {
    return request('POST', 'oauth_provider', null, {
      team_key: teamKey,
      remote_client_id: clientID,
    }, null)
  }

  exchangeAuthCode(code, state, teamKey) {
    return request('POST', 'auth_code_exchange', null, {
      code,
      state,
      team_key: teamKey,
      remote_client_id: clientID,
    }, null)
  }

  getMe() {
    return request('GET', 'me')
  }

  // Uses Admin API
  getOrganizations(query) {
    return request('GET', 'organizations', query, null, undefined, true)
  }

  getOrganization(query) {
    return request('GET', 'organization', query)
  }

  saveOrganization(orgData) {
    return request('PATCH', `organization`, {include: ['address']}, {organization: orgData})
  }

  getStores(query) {
    return request('GET', 'stores', query)
  }

  getStore(storeID) {
    return request('GET', `stores/${storeID}`, {
      include: [
        'address',
        'region',
        'floors',
        'areas',
        // 'areas.geometry',
        'fixtures',
        // 'fixtures.geometry',
        'fixtures.positions',
        // 'fixtures.positions.geometry',
        'security_devices',
        'connect_hubs',
      ],
    })
  }

  saveStore(storeData) {
    const method = storeData.id ? 'PATCH' : 'POST'
    const endpoint = storeData.id ? `stores/${storeData.id}` : 'stores'
    return request(method, endpoint, {include: ['address']}, {store: storeData})
  }

  getPhysicalKeys(query) {
    return request('GET', 'physical_keys', query)
  }

  getVirtualKeys(query) {
    return request('GET', 'virtual_keys', query)
  }

  saveKey(key) {
    const method = key.id ? 'PUT' : 'POST'
    const endpoint = key.id ? `physical_keys/${key.id}` : 'physical_keys'
    return request(method, endpoint, null, { physical_key: key })
  }

  deleteKey(id) {
    return request('DELETE', `physical_keys/${id}`)
  }

  // Zones (floors, areas, fixtures, positions)
  destroyZoneComponent(type, id) { return request('DELETE', `${type}s/${id}`) }
  saveZoneComponent(type, resource) {
    let path = `${type}s`
    let method = 'POST'
    if (resource.id) {
      path = `${path}/${resource.id}`
      method = 'PATCH'
    } else {
      // If this is a new zone component, then add Gen2-specific properties so that we don't break
      // the layout.
      resource = {
        geometry: {
          x: 0.5,
          y: 0.33,
          width: 0.25,
          height: 0.25,
        },
        shouldDisplayName: true,
        ...resource,
      }
    }
    return request(method, path, null, { [type]: resource })
  }

  // Users
  getUsers(query) { return request('GET', 'users', query) }
  getUser(userID) { return request('GET', `users/${userID}`, {include: ['roleResources']}) }
  saveUser(user, persona, resourceIDs) {
    const method = user.id ? 'PATCH' : 'POST'
    const endpoint = user.id ? `users/${user.id}` : 'users'
    return request(method, endpoint, {include: ['roleResources']}, {
      user: user,
      persona: persona,
      resourceIds: resourceIDs,
    })
  }

  savePassword(user) {
    const userData = {}
    if (user.password) userData.password = user.password
    if (user.passcode) userData.passcode = user.passcode
    return request('PATCH', `users/${user.id}`, null, {user: userData})
  }

  activateUser(userID) {
    return request('POST', `users/${userID}/activate`)
  }

  deactivateUser(userID) {
    return request('POST', `users/${userID}/deactivate`)
  }

  getAssignableRoles() {
    return request('GET', 'users/assignable_roles')
  }

  // Regions
  getRegions(query) { return request('GET', 'regions', query) }
  getRegion(id) { return request('GET', `regions/${id}`, { include: 'stores' }) }
  destroyRegion(id) { return request('DELETE', `regions/${id}`) }
  saveRegion(region) {
    return region.id
      ? request('PATCH', `regions/${region.id}`, null, {region})
      : request('POST', `regions`, null, {region})
  }

  getReport(filters) {
    // Example request:
    // http://api.localhost:3000/api/reports?reporting_report_filters[reports][]=marketing&reporting_report_filters[range_start]=2024-01-30&reporting_report_filters[range_end]=2024-02-05&page=0&per=100&store_id=3
    const { reportType, startDate, endDate, storeID, ...pagination } = filters
    const query = {
      'reporting_report_filters[reports][]': reportType, // marketing, operations, health
      'reporting_report_filters[range_start]': startDate, // example: '2024-01-30'
      'reporting_report_filters[range_end]': endDate, // example: '2024-01-30'
      'store_id': storeID,
      ...pagination,
    }
    return request('GET', 'reports', query)
  }

  startProvisionMode(storeID, hubID) {
    return request('PATCH', `stores/${storeID}/connect_hubs/${hubID}/provision`)
  }

  getIOTReports(query) {
    return request('GET', 'offline_report', query)
  }

  requestResetPassword(userData) {
    return request('POST', 'passwords/forgot', null, {user: userData})
  }

  resetPassword(userData) {
    return request('POST', 'passwords/reset', null, {user: userData})
  }

  // Security Devices
  getSecurityDevice(deviceID) {
    return request('GET', `security_devices/${deviceID}`, {include: 'secured_products'})
  }

  getSecurityDeviceBySerial(serialNumber) {
    return request('GET', `security_devices`, {serial_number: serialNumber})
  }

  sendCommand(command, deviceID) {
    if (!['silence','identify','disarm','arm'].includes(command)) return
    return request('POST', `security_devices/${deviceID}/${command}`)
  }

  // Secured Products
  saveSecuredProduct(securedProduct) {
    return securedProduct.id
      ? request('PATCH', `secured_products/${securedProduct.id}`, null, {securedProduct})
      : request('POST', `secured_products`, null, {securedProduct})
  }

  destroySecuredProduct(securedProductID) {
    return request('DELETE', `secured_products/${securedProductID}`, null)
  }

  // Virtual Key
  createVirtualKey(storeID) {
    return request('POST', `stores/${storeID}/virtual_keys`)
  }

  getVirtualKey(keyID) {
    return request('GET', `virtual_keys/${keyID}` )
  }
}

// The api provider that will update the api's session token when it changes
export function APIProvider(props) {
  // Using the same object instance for the value of this provider will make it so that when this
  // provider updates, due to the session changing, it doesn't trickle down to all components that
  // simply use the API, since they don't need to know or care. But the session on that api will
  // always be updated with a reference to the session and token.
  const api = API.instance
  api._session = useSession()
  const { organization } = useOrganization()
  api._orgID = organization?.id
  // api.errors = useErrors()
  // TODO: set the API's selected organization id based on the organization context

  return (
    <Context.Provider value={api}>
      {props.children}
      <IdleTimer />
    </Context.Provider>
  )
}



export default function useAPI() {
  return useContext(Context)
}

/****************************************************************
 * Util Functions
 ****************************************************************/

function queryString(obj = {}) {
  return Object.keys(obj)
    .filter(key => !(obj[key] === undefined || obj[key] === null))
    .map(key => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

// A request wrapper function to handle domain, query params, pathing, token, and 401 and other error handling.
function request(method, path, query, data, token = undefined, hasOrganizationsUrl = false) {
  if (token === undefined) token = API.instance._session?.token
  const completeQuery = {
    include: [], // default include should be empty
    organizationId: API.instance._orgID,
    ...query,
  }

  const api = hasOrganizationsUrl ?  'admin_api' : 'api'

  return new Promise((resolve, reject) => {
    try {
      httpRequest(method, `${apiHost}${api}/${path}?${queryString(completeQuery)}`, data, token).then(response => {
        if (response.ok) resolve(response.body)
        else {
          // TODO: handle any generic errors by adding them to the errors context
          if (response.status === 401)
            API.instance._session?.setAuthentication(null)
          reject(response)
        }
      }).catch(response => {
        console.warn('caught inside catch() of httpRequest in request() function.', response)
        reject(response)
      })
    } catch (e) {
      console.warn('caught inside request function', e)
    }
  })
}

// A base http request helper function to simplify the built-in fetch function and response
function httpRequest(method, url, data, token) {
  return fetch(url, {
    method,
    headers: {
      'content-type': 'application/json; charset=utf-8',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then(response => {
    const value = {
      status: response.status,
      ok: response.ok,
      body: null,
      error: null,
    }

    // If response is not ok, parse the error so we can check if we need OTP login.
    if (!response.ok) {
      return response.json().then(errorBody => {
        return {
          ...value,
          error: errorBody,
        }
      }).catch(() => ({
        ...value,
        ok: false,
        error: Error('Failed to parse server response.'),
      }))
    }

    if (response.ok)
      // try to parse the body as well
      return response.json().then(body => ({
        ...value,
        body,
      })).catch(() => ({
        ...value,
        ok: false,
        error: Error('Failed to parse server response.'),
      }))
    value.error = Error('Request failed.')
    return value
  }).catch(() => {
    // this means the fetch threw an error, possibly because
    // it couldn't communicate with the network or the DNS lookup failed
    console.error('Unable to communicate with the MTI Connect server.')
    return {
      ok: false,
      error: Error('Unable to communicate with the server.'),
    }
  })
}

function getAPIHost() {
  let host = process.env.REACT_APP_API_HOST
  let apiURL = host ? new URL(process.env.REACT_APP_API_HOST) : null

  // If we're running locally, on a device or in an emulator, replace localhost with whatever our
  // app's hostname is, since that will be our local IP.
  let appHost = window.location.hostname
  if (apiURL?.hostname == 'api.localhost' && appHost != 'localhost')
    apiURL.hostname = appHost

  return apiURL?.toString()
}
