import { useTranslation } from 'react-i18next'
import { useStore } from 'global'
import { toFriendlyDate, toFriendlyTime, camelize, useDeviceStateFor, iconFor } from 'utils'
import { UnhealthyIssue } from 'components'
import './styles.scss'

export default function DeviceDetails({ securityDevice: sd }) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { securityDevices, securityDevicesByParent, positionsBySecurityDevice } = useStore()
  const securityDevice = securityDevices[sd.id] // Get the data from the context instead, so we can update our state in realtime
  const associatedDevice = securityDevices[securityDevice.parentId] || securityDevicesByParent[securityDevice.id]
  const pos = positionsBySecurityDevice[securityDevice.id]
  const assocPos = positionsBySecurityDevice[associatedDevice?.id]
  const position = pos || assocPos
  const state = useDeviceStateFor()(securityDevice)

  const unhealthyInfo = state.unhealthyReason && <UnhealthyIssue reason={state.unhealthyReason} ports={securityDevice.unhealthyPorts} />

  return (
    <div className='device-details-comp col'>
      <div className='row'>
        <p className={!position ? 'unassigned' : ''}>{position?.name || t('device.unassigned')}</p>
        {iconFor(state.security)}
        {state.alarming && iconFor(state.alarming)}
      </div>
      <div className='col devices-container'>
        <div className='col device-container'>
          <p className='subtitle'>{t(`device.type.${camelize(securityDevice.deviceType)}`)}</p>
          <p className='serial'>{securityDevice.serialNumber}</p>
        </div>
        {associatedDevice &&
          <div className='col device-container'>
            <p className='subtitle'>{t(`device.type.${camelize(associatedDevice.deviceType)}`)}</p>
            <p className='serial'>{associatedDevice.serialNumber}</p>
          </div>
        }
      </div>
      <div className='col'>
        <p className='subtitle'>{t('device.updatedAt', { date: toFriendlyDate(securityDevice.updatedAt), time: toFriendlyTime(securityDevice.updatedAt) })}</p>
        <p className='subtitle'>{t('device.createdAt', { date: toFriendlyDate(securityDevice.createdAt), time: toFriendlyTime(securityDevice.createdAt) })}</p>
      </div>
      {unhealthyInfo}
    </div>
  )
}
