import { useTranslation } from 'react-i18next'
import { useAPI, useStore } from 'global'
import { toast } from 'react-toastify'
import { StoreEdit, openModal, closeModal } from 'components'
import Screen from './screen.jsx'

export default function StoreDetails() {
  const { t } = useTranslation(null, { useSuspense: false })
  const { store, address, region, updateStore } = useStore()

  const api = useAPI()

  const onProvision = () => {
    if (!store.connectHubIds?.length) return toast.error(t('error.provisionMode'))

    const hubID = Math.max(...store.connectHubIds)
    api.startProvisionMode(store.id, hubID).then(()=>{
      toast.success(t('success.provisionMode'))
    }).catch(()=>{
      toast.error(t('error.provisionMode'))
    })
  }

  const onEdit = () => openModal(<StoreEdit storeData={{ store, address, region }} onFinish={onFinish} />)

  const onFinish = storeData => {
    closeModal()
    updateStore(storeData.store, storeData.address, storeData.region)
  }

  return (
    <Screen onProvision={onProvision} onEdit={onEdit} />
  )
}
