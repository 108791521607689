import { useTranslation } from 'react-i18next'
import { useAPI } from 'global'
import { toast } from 'react-toastify'
import Screen from './screen'

export default function StoreEdit(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const {
    storeData,
    onFinish,
  } = props

  const onSubmit = (store, address, region) => {
    const combinedData = {...store, address: {...address}}
    api.saveStore(combinedData).then(response => {
      toast.success(t(store?.id ? 'success.storeEdit' : 'success.storeCreate'))
      const data = {
        store: response?.stores?.[0],
        address: response?.addresses?.[0],
        region: region,
      }
      onFinish?.(data)
    }).catch(()=>{
      toast.error(t('error.storeSave'))
    })
  }

  return (
    <Screen storeData={storeData} onSubmit={onSubmit} onFinish={onFinish} />
  )
}
