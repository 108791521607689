import { useEffect, useRef } from 'react'
import { useStore } from 'global'
import mqtt from 'components/mqtt'

const listenerID = 'realtimeUpdater'

// RealtimeUpdater is a UI-less functional component that listens for status updates from mqtt, and
// updates the state of devices within the store context which, in turn, results in the UI updating in realtime.
export default function RealtimeUpdater() {
  const { securityDevicesBySerial, updateSecurityDevice } = useStore()

  // Keep a reference to the security devices by serial, because we need the mqtt listener to be able to reference these.
  const devices = useRef(securityDevicesBySerial)
  useEffect(() => {
    devices.current = securityDevicesBySerial
  }, [securityDevicesBySerial])

  useEffect(() => {
    mqtt.listen(listenerID, (topic, msg) => {
      console.log('realtimeUpdater received message', topic, msg)
      let serialNumber
      const state = { }
      switch (topic) {
      case 'lock/state':
        serialNumber = msg.serialNumber
        state.alarming = msg.lockAlarming || msg.lockDoorAlarm || msg.lockPullAlarm
        state.silentAlarming = msg.silentAlarming
        state.locked = msg.lockLocked
        state.open = msg.lockOpen
        state.armed = msg.lockArmed
        break
      case 'fm_20/state':
        serialNumber = msg.puckSerialNumber
        state.armed = msg.armed
        state.alarming = msg.tetherAlarming || msg.deviceAltAlarming || msg.deviceAlarming || msg.cableAlarming || msg.baseLiftAlarming || msg.puckSwitchAlarming
        state.silentAlarming = msg.silentAlarming
        break
      case 'core_iii/state':
        serialNumber = msg.serialNumber
        state.armed = msg.armed
        state.alarming = msg.baseLiftAlarming
        state.silentAlarming = msg.silentAlarming
        for (let i = 1; i<=10; i++) {
          let p = msg[`port${i}`]
          state.alarming = state.alarming || p?.primaryDeviceAlarming || p?.secondaryDeviceAlarming
        }
        break
      default:
        serialNumber = msg.serialNumber
        state.armed = msg.armed
        if (msg.silentAlarming != undefined) state.silentAlarming = msg.silentAlarming
        for (let key in msg) {
          if (key.includes('Alarming') && key != 'silentAlarming')
            state.alarming = state.alarming || msg[key]
        }
      }

      // TODO: We may want to do more complex state calculations for these in the future. (For
      // example, recording the individual port states for a cx flex to show which port is alarming
      // if one starts or stops alarming in realtime.

      const sd = devices.current?.[serialNumber]
      if (hasChanged(sd, state))
        updateSecurityDevice({ ...sd, ...state })
    })
    return () => mqtt.unlisten(listenerID)
  }, [])

  // This component has no UI
  return <></>
}

function hasChanged(device, newState) {
  if (!device) return false
  for (let key in newState)
    if (newState[key] != device[key]) return true
  return false
}
