function toEnum(value, type) {
  for (let k of Object.keys(type))
    if (type[k] == value) return k
  return null
}

// Creates the enum as a function with the keys as static properties.
// When called with the value, the function will return the enum associated with that value.
function createEnum(values) {
  const type = v => toEnum(v, type)
  for (let k of Object.keys(values))
    type[k] = values[k]
  return type
}

const DeviceStates = [
  'alarming',
  'armed',
  'disarmed',
  'healthy',
  'locked',
  'open',
  'silentAlarming',
  'unassigned',
  'unhealthy',
  'unknown',
  'unlocked',
]

// a simple enum for position states
export const DeviceState = DeviceStates.reduce((obj, s) => { obj[s] = s; return obj }, { })

export const DeviceType = createEnum({
  base: 'base',
  puck: 'puck',
  lock: 'lock',
  alarmModule: 'alarm_module',
  securePlug: 'secure_plug',
  cxFlex: 'core_iii', // deprecated
  cxFlex1: 'core_iii_one_port',
  cxFlex4: 'core_iii_four_port',
  fm2Base: 'base_v2',
  fm2Puck: 'puck_v2',
  proximityBase: 'proximity_base',
  proximityPuck: 'proximity_puck',
  nxdiBase: 'di_riser',
  nxdiPuck: 'di_puck',
  dumbPuck: 'dumb_puck',
  ram: 'ram',
})

// Roles are the different possible user role keys
export const Role = createEnum({
  // app provider roles
  superUser: 'super_user',
  tierOneSupport: 'tier_one_support',
  fieldTechnician: 'field_technician',

  // client user roles
  keyUser: 'key_user',
  organizationAdministrator: 'organization_administrator',
  regionalManager: 'regional_manager',
  storeAssociate: 'store_associate',
  storeManager: 'store_manager',
  storeTechnician: 'store_technician',
  supportAnalyst: 'support_analyst',
  thirdPartyAssociate: 'third_party_associate',
  userAdministrator: 'user_administrator',
})

export const KeyType = createEnum({
  ble: 'BLE',
  ephemeral: 'EPHEMERAL',
  rfid: 'VERSA',
})
